import React, { ComponentProps } from 'react';
import classNames from 'classnames';

import { ButtonColor, ButtonVariant } from '../Types/constants';
import { buildButtonClassNames, getButtonVariant } from '../Utils/Button.utils';

import { LinkBase } from './LinkBase';

export interface IProps extends Omit<ComponentProps<typeof LinkBase>, 'color'> {
	color?: ButtonColor;
	isTransparent?: boolean;
	variant?: ButtonVariant;
}

export const Link = (props: IProps) => {
	const {
		className = '', color, isTransparent, variant, ...linkProps
	} = props;
	const linkVariant = getButtonVariant(variant);
	const buttonClassName = buildButtonClassNames(linkVariant, color, isTransparent);

	return (
		<LinkBase
			{...linkProps}
			className={classNames(buttonClassName, {
				[className]: className,
			})}
		>
			{props.children}
		</LinkBase>
	);
};
