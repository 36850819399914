import axios from 'axios';
import { ComponentProps } from 'react';
import {
	model, idProp, modelFlow, _await, modelAction, prop, ExtendedModel, modelClass,
} from 'mobx-keystone';
import { IReactionDisposer, reaction } from 'mobx';

import type { IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';
import { AbstractFormStore } from '~/util/abstract-form-store/AbstractForm.store';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { modelNamespace } from '~/util/modelNamespace';
import { FormSubmitEventTrackingStore } from '~/tracking/form-submit-event/Stores/FormSubmitEvent.tracking.store';
import { IOptInPayload, TextOptInViewStateConfig } from '~/text-opt-in/Types/TextOptIn.type';
import {
	TextOptInFormVariant, TextOptInPageViewState, TextOptInSubscriptionSource, textOptInFormFields,
} from '~/text-opt-in/Types/TextOptIn.constants';
import { TextOptInForm } from '~/text-opt-in/Components/TextOptInForm';
import { TextOptInSuccess } from '~/text-opt-in/Components/TextOptInSuccess';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { FormReactionsPlugin } from '~/util/formz/plugins/FormReactionsPlugin';
import { isOnServer } from '~/global/global.constants';

type FormModel = {
	mobilePhone: string,
	optIn: boolean,
}

@model(`${modelNamespace.TEXT_OPT_IN}/TextOptInForm`)
export class TextOptInStore extends ExtendedModel(() => ({
	baseModel: modelClass<AbstractFormStore<FormModel>>(AbstractFormStore),
	props: {
		id: idProp,
		isSubmitted: prop<boolean | undefined>(false).withSetter(),
		optInFinePrintVisible: prop<boolean>(false).withSetter(),
		source: prop<TextOptInSubscriptionSource>(),
		variant: prop<TextOptInFormVariant>(),
	},
})) {
	onInit() {
		super.onInit();
		this.viewState = new ViewStateStore(this, this.viewStateConfig);
		this.viewState?.goTo(TextOptInPageViewState.OptIn);
		this.formSubmitResolve = this.successfulFormSubmission;
		// we are not doing any error handling, all treated as success
		this.formSubmitReject = this.successfulFormSubmission;
	}

	optInForm: any;

	disposers: IReactionDisposer[] = [];

	get formModel(): FormModel {
		return {
			mobilePhone: '',
			optIn: false,
		};
	}

	get formSettings(): IFormSettings {
		const self = this;

		return {
			id: 'text-opt-in-form',
			reactProps: {
				method: 'POST',
			},
			settings: {
				plugins: [
					new ValidateAndAjaxSubmitPlugin({
						ajaxSubmit: {
							submitHandler: self.submitHandler.bind(self),
							promiseHandler: self.promiseHandler.bind(self),
							// submitHandler: () => this.submitOptInForm(),
							// promiseHandler: () => this.processSignupForm(),
						},
					}),
					new FormReactionsPlugin({
						reaction: (form: any) => {
							if (isOnServer) {
								return;
							}
							this.disposers.push(
								reaction(() => form.model.optIn, (value) => {
									if (value) {
										form.plugins.formValidator.validateForm();
									}
									if (this.isMMSModalVariant) {
										this.scrollToHeadline();
									}
									if (this.isEmailMMSModalVariant) {
										this.scrollToPhoneField();
									}
								}),
							);
						},
					}),
				],
			},
			fields: textOptInFormFields,
		};
	}

	get payload(): string {
		return JSON.stringify({
			mobileNumber: this.formattedMobileNumber,
			optInSource: this.source,
		});
	}

	get saveUrl(): string {
		return '/api/marketing-text-messaging';
	}

	textOptInFormSuccessCallback: any;

	viewState: ViewStateStore | null = null;

	viewStateConfig: TextOptInViewStateConfig = {
		[TextOptInPageViewState.Loading]: {
			component: LoadingSpinner,
			onEnter: (): ComponentProps<typeof LoadingSpinner> => ({
				isLoading: true,
			}),
		},
		[TextOptInPageViewState.OptIn]: {
			component: TextOptInForm,
			onEnter: (_, store: TextOptInStore): ComponentProps<typeof TextOptInForm> => ({
				store,
			}),
		},
		[TextOptInPageViewState.Success]: {
			component: TextOptInSuccess,
			onEnter: (_, store: TextOptInStore): ComponentProps<typeof TextOptInForm> => ({
				store,
			}),
		},
		[TextOptInPageViewState.Error]: {
			component: TextOptInForm,
			onEnter: (_, store: TextOptInStore): ComponentProps<typeof TextOptInForm> => ({
				store,
			}),
		},
	};

	get disableSignUpBtn() {
		return !this.form?.model.optIn || this.form?.plugins?.formValidator?.hasErrors;
	}

	get formattedMobileNumber() {
		const onlyNumbers = this.form.model.mobilePhone.replaceAll('-', '');
		return `1${onlyNumbers}`;
	}

	get isEmailMMSModalVariant() {
		return this.variant === TextOptInFormVariant.EMAIL_MMS_MODAL;
	}

	get isMMSStandalonePageVariant() {
		return this.variant === TextOptInFormVariant.STANDALONE_PAGE;
	}

	get isMMSModalVariant() {
		return this.variant === TextOptInFormVariant.MMS_MODAL;
	}

	get showSignUpButton() {
		if (this.isEmailMMSModalVariant && this.form?.model.mobilePhone.length) {
			return true;
		}

		if (this.isMMSStandalonePageVariant || this.isMMSModalVariant) {
			return true;
		}

		return false;
	}

	get showOptInCheckbox() {
		if (this.isEmailMMSModalVariant && this.form?.model.mobilePhone.length) {
			return true;
		}

		if (this.isMMSStandalonePageVariant || this.isMMSModalVariant) {
			return true;
		}

		return false;
	}

	get showOptInFinePrint() {
		// once the fine print is visible it remains visible
		if (this.optInFinePrintVisible) {
			return true;
		}

		if (this.isEmailMMSModalVariant && this.form?.model.mobilePhone.length) {
			this.setOptInFinePrintVisible(true);
			return true;
		}

		if ((this.isMMSStandalonePageVariant || this.isMMSModalVariant) && this.form?.model.optIn) {
			this.setOptInFinePrintVisible(true);
			return true;
		}

		return false;
	}

	get shouldScroll() {
		return window.matchMedia('(max-width: 40em)').matches;
	}

	scrollToHeadline() {
		if (!this.shouldScroll) {
			return;
		}

		const headline = document.querySelector('[data-qa="mms-modal-headline"]');

		if (headline) {
			setTimeout(() => {
				headline.scrollIntoView({ behavior: 'smooth' });
			}, 0);
		}
	}

	scrollToPhoneField() {
		if (!this.shouldScroll) {
			return;
		}

		const phoneField = document.querySelector('[data-qa="sign-up-texts-phone-field"]');

		if (phoneField) {
			setTimeout(() => {
				phoneField.scrollIntoView({ behavior: 'smooth' });
			}, 0);
		}
	}

	scrollToModalTop() {
		if (!this.shouldScroll) {
			return;
		}

		const modalConatiner = document.querySelector('[data-selector="modal-container"]');

		if (modalConatiner) {
			setTimeout(() => {
				modalConatiner.scrollIntoView({ behavior: 'smooth' });
			}, 0);
		}
	}

	setTextOptInFormSuccessCallback(callback: Function) {
		this.textOptInFormSuccessCallback = callback;
	}

	@modelFlow
	submitOptInForm = (async function* (this: TextOptInStore) {
		this.form?.plugins.formValidator.validateForm();

		if (!this.form || this.form?.plugins?.formValidator?.hasErrors) {
			return Promise.reject();
		}

		this.viewState?.goTo(TextOptInPageViewState.Loading);

		const optInPayload: IOptInPayload = {
			mobileNumber: this.formattedMobileNumber,
			optInSource: this.source,
		};

		return yield* _await(axios.request({ url: '/api/marketing-text-messaging', method: 'post', data: optInPayload }));
	});

	@modelAction
	successfulFormSubmission = () => {
		// Not currently handling any errors
		const formSubmitTracking = new FormSubmitEventTrackingStore();

		formSubmitTracking.trackFormSubmit(this.formSettings.id);

		// Send the interact event to Target
		if (this.textOptInFormSuccessCallback) {
			this.textOptInFormSuccessCallback();
		}

		this.scrollToModalTop();

		this.viewState?.goTo(TextOptInPageViewState.Success);
		this.setIsSubmitted(true);
	};
}
