import React from 'react';
import { observer } from 'mobx-react';

import { s7ImagePath } from '~/global/global.constants';
import { addToUrl } from '~/util/addToUrl';

import styles from '#/layout/email-signup.module.scss';

export const EmailSignUpModalImage = observer(({ imageName, imageWidth }) => {
	const srcRoot = `${s7ImagePath}/${imageName}`;

	const srcAttr = addToUrl(srcRoot, `wid=${imageWidth}`);

	const srcSetAttr = addToUrl(srcRoot, `wid=${imageWidth * 2}`);

	return (
		<img
			alt=""
			className={styles['email-signup-modal-image']}
			src={srcAttr}
			srcSet={`${srcSetAttr} 2x`}
		/>
	);
});
