import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { EmailSignUpFormViewState } from '~/email-signup/Components/EmailSignUpForm';
import { EmailSignUpModalImage } from '~/email-signup/Components/EmailSignUpModalImage';
import { noop } from '~/util/noop';

import styles from '#/layout/email-signup.module.scss';

export const EmailSignUpModalNew = observer((props = {}) => {
	const {
		clickHandler = noop,
		emailSignUpFormStore = {},
		headingText = '',
		imageName = '',
		imageWidth = '',
		isNoImageEmailSignUpModalContent = false,
		subheadingText = '',
	} = props;

	return (
		<div className={
			classNames(`${styles['email-signup-modal']} email-signup-modal-${emailSignUpFormStore.locationId}`, {
				[styles['email-signup-modal-no-image']]: isNoImageEmailSignUpModalContent
			})
		}>
			{
				(!isNoImageEmailSignUpModalContent) &&
					<EmailSignUpModalImage imageName={imageName} imageWidth={imageWidth} />
			}
			<div className={styles['email-signup-modal-content']}>
				<div className={styles['email-signup-modal-heading-container']}>
					{
						headingText &&
							<h1 className={`Heading Heading--3 ${styles['email-signup-modal-heading']}`}>
								{headingText}
							</h1>
					}

					{
						subheadingText &&
							<p className={styles['email-signup-modal-subheading']}>
								{subheadingText}
							</p>
					}
				</div>

				<div className="email-signup-modal-form-container">
					<EmailSignUpFormViewState emailSignUpFormStore={emailSignUpFormStore} clickHandler={clickHandler} />
				</div>
			</div>
		</div>
	);
});
