import React from 'react';

import {
	s7ImagePath,
} from '~/global/global.constants';
import { LazyPicture } from '~/util/Components/LazyPicture';
import { Link } from '~/components/Buttons/Components/Link';
import { TextLink } from '~/components/Buttons/Components/TextLink';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';

export const MMSGiveawayModalDefault = (props) => {
	const {
		interactEventHandler,
	} = props;

	return (
		<div>
			<div className="tw-block md:tw-flex md:tw-items-end tw-items-center">
				<div className="tw-w-full md:tw-w-[32%]">
					<LazyPicture
						smallSrcSet={`${s7ImagePath}/mmsGiveawayModal_sm?size=400,261&scl=1&cb2, ${s7ImagePath}/mmsGiveawayModal_sm?size=800,522&scl=1&cb2 2x`}
						mediumSrcSet={`${s7ImagePath}/mmsGiveawayModal?size=210,420&scl=1&cb, ${s7ImagePath}/mmsGiveawayModal?size=420,840&scl=1&cb 2x`}
						fallbackSrc={`${s7ImagePath}/mmsGiveawayModal?size=210,420&scl=1&cb`} />
				</div>
				<div className="tw-w-full md:tw-w-[68%] md:tw-px-6 md:tw-py-0 tw-p-4">
					<h2 className="tw-heading-3 tw-mb-3">$5,000 Gift Card Sweepstakes<sup>†</sup></h2>
					<p className="tw-mb-5">Opt in to win! Now through November 11, sign up for text updates and you’ll be entered for a chance to win a $5,000 Room & Board gift card.</p>
					<Link color={ButtonColor.Gray}
						onClick={interactEventHandler}
						href=" https://go.roomandboard.com/2F1158"
						variant={ButtonVariant.Hollow}
						isTransparent={true}
						target="_blank"
					>
								Enter Now
					</Link>
					<p className="md:tw-mt-12 tw-mt-4 md:tw-mb-4 tw-text-2xs tw-text-gray-300"><sup>†</sup>Void where prohibited. No purchase necessary to enter; a purchase will not increase your chance of winning. Subject to <TextLink href="https://go.roomandboard.com/FEQ29a" target="_blank"><span className="tw-text-2xs">Official Rules</span></TextLink>.</p>
				</div>
			</div>
		</div>
	);
};
